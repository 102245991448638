.color {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
}

.box {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.info {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  color: #fff;
  font-family: "Fira Code", sans-serif;
  font-weight: 300;
  padding: 2em;
  text-align: center;
}
